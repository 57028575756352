.stocks-form-popup {
    .popup-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        background-color: $white;
        @include grid-columns(6);
        height: fit-content;
        padding: 20px;
        border-radius: 18px;

        @include tablet {
            @include grid-columns(12);
        }

        @include mobile {
            padding-bottom: 40px;
        }
    }
}

.stocks-form-popup__container {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    gap: 60px;
    padding-block: 10px 30px;
    padding-inline: 40px;
    width: 100%;

    @include mobile {
        padding: 0;
    }
}

.stocks-form-popup__top {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;

    @include mobile {
        flex-direction: column;
        gap: 25px;
    }
}

.stocks-form-popup-title {
    @include grid-columns(3);
    max-width: 270px;
    width: 50%;

    @include tablet {
        max-width: 225px;
    }

    @include mobile {
        width: 100%;
        max-width: 100%;
    }
}
.stocks-form-popup__description {
    @include grid-columns(3);
    width: 50%;

    @include mobile {
        width: 100%;
        max-width: 100%;
    }
}

.stocks-form-popup-form {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.stocks-inputs-block {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--grid-gap);

    @include mobile {
        flex-direction: column;
    }
}

.stocks-input-block {
    width: calc(50% - var(--grid-gap) / 2);
    gap: 0;
    @include mobile {
        width: 100%;

        .organize-form-popup-input {
            padding: 16px;
        }
    }
}

.stocks-input-name {
    margin-bottom: 18px;

    @include mobile {
        margin-bottom: 12px;
    }
}

.stocks-form-popup-privacy {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .form-checkbox-wrapper {
        @include grid-columns(3, max-width);
        max-width: calc(50% - var(--grid-gap) / 2);

        @include mobile {
            max-width: 100%;
        }
    }

    @include mobile {
        flex-direction: column;
        gap: 30px;
    }

    .stocks-form-popup-submit {
        width: fit-content;
    }
}
